import '../sidemodal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CustomButton from '../../../components/custom-button/custom-button';
import SidebarInputField from '../components/sidebar-input-field/sidebar-input-field';
import SidebarSelectField from '../components/sidebar-select-field/sidebar-select-field';
import useAlertStore from '../../../stores/alertStore';
import { useTranslation } from 'react-i18next';
import FileUpload, { UploadedFile } from '../../../components/file-upload/file-upload';
import FileCard from '../../../components/file-card/file-card';
import ExpandableSection from '../../../components/expandable-section/expandable-section';
import { useUnits } from '../../../hooks/useUnits';
import { useProperties } from '../../../hooks/useProperties';
import { useInvestors } from '../../../hooks/useInvestors';
import { IUnitOwner, createUnitOwner, uploadUnitOwnerDocuments } from '../../../services/unitOwnerService';

interface AddUnitOwnerSidebarModalProps {
    isVisible: boolean;
    propertyId?: number;
    unitId?: number;
    investorId?: number;
    onClose: () => void;
    onAddUnitOwner: (unitOwner: IUnitOwner) => void;
}

const AddUnitOwnerSidebarModal: React.FC<AddUnitOwnerSidebarModalProps> = ({ isVisible, propertyId, unitId, investorId, onClose, onAddUnitOwner }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    const { properties, isReachingEnd: isReachingPropertiesEnd } = useProperties();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [investorsSearchQuery, setInvestorsSearchQuery] = useState<string>("");
    const [unitsSearchQuery, setUnitsSearchQuery] = useState<string>("");
    const [property, setProperty] = useState<string>(() => {
        if (propertyId) {
            return propertyId.toString();
        }
        if (properties.length > 0) {
            return properties[0].property_id.toString();
        }
        return "";
    });
    const [unit, setUnit] = useState<string>(unitId ? unitId.toString() : "");
    const [investor, setInvestor] = useState<string>(investorId ? investorId.toString() : "");
    const [shares, setShares] = useState<string>("");
    const [purchasePrice, setPurchasePrice] = useState<string>("");
    const [sharesError, setSharesError] = useState<string>("");
    const [purchasePriceError, setPurchasePriceError] = useState<string>("");
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [error, setError] = useState<string>("");

    // Hooks
    const { investors, setSize: setInvestorsSize, isReachingEnd: isReachingInvestorsEnd } = useInvestors(null, investorsSearchQuery);
    const { units, setSize: setUnitsSize, isReachingEnd: isReachingUnitsEnd } = useUnits(property.length > 0 ? Number(property) : null, unitId ?? null, unitsSearchQuery);

    // Clean states
    useEffect(() => {
        if (!isVisible) {
            setShares("");
            setPurchasePrice("");
            setSharesError("");
            setPurchasePriceError("");

            setFiles([]);
            setError("");
        }
    }, [isVisible]);

    const onFileSelected = (selectedFile: UploadedFile[]) => {
        setFiles(prevFiles => [...prevFiles, ...selectedFile]);
    };

    // Function to remove a file from the state
    const removeFile = (fileIndex: number) => {
        setFiles(prevFiles => prevFiles.filter((_, index) => index !== fileIndex));
    };

    // Create Lease
    const onHandleCreateUnitOwner = useCallback(async () => {
        let isValid = true;

        // Check if the shares is not empty
        if (!shares.trim()) {
            setSharesError(t("invalid_input.shares_empty"));
            isValid = false;
        }

        // Check if the purchase price is not empty
        if (!purchasePrice.trim()) {
            setPurchasePriceError(t("invalid_input.purchase_price_empty"));
            isValid = false;
        }

        if (isValid && property.length > 0 && unit.length > 0 && investor.length > 0) {
            try {
                setError("");
                setIsLoading(true);

                const createdUnitOwner = await createUnitOwner(
                    Number(unit),
                    Number(investor),
                    Number(shares),
                    Number(purchasePrice),
                );

                // Upload files
                if (files.length > 0) {
                    await uploadUnitOwnerDocuments(Number(unit), createdUnitOwner.unit_owner_id, files.map(uploadedFiles => uploadedFiles.file));
                }

                onAddUnitOwner(createdUnitOwner);

                onClose()
                showAlert("success", t("modals.add_unit_owner.add_success_message"));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [shares, purchasePrice, property, unit, investor, files, onAddUnitOwner, onClose, showAlert, t]);

    const onSearchUnit = useCallback((searchQuery: string, page: number) => {
        setUnitsSearchQuery(searchQuery);
        setUnitsSize(page);
    }, [setUnitsSize]);

    const onSearchInvestor = useCallback((searchQuery: string, page: number) => {
        setInvestorsSearchQuery(searchQuery);
        setInvestorsSize(page);
    }, [setInvestorsSize]);

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">{t("modals.add_unit_owner.title")}</h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">
                        <div className="row">
                            {!propertyId && (
                                <div className="col-12 col-md-6">
                                    <SidebarSelectField
                                        id="property"
                                        label={t("labels.property")}
                                        value={property}
                                        onChange={setProperty}
                                        options={properties.map(property => ({
                                            label: property.property_name,
                                            value: property.property_id.toString()
                                        }))}
                                        isEndReached={isReachingPropertiesEnd}
                                        required
                                        labelOnTop
                                    />
                                </div>
                            )}
                            {!unitId && (
                                <div className="col-12 col-md-6">
                                    {property && (
                                        <SidebarSelectField
                                            id="unit"
                                            label={t("labels.unit")}
                                            placeholder={t("placeholders.search")}
                                            value={unit}
                                            onChange={setUnit}
                                            options={units.map(unit => ({
                                                label: unit.unit_number,
                                                value: unit.unit_id.toString()
                                            }))}
                                            onSearch={onSearchUnit}
                                            isEndReached={isReachingUnitsEnd}
                                            required
                                            labelOnTop
                                            isSearchable
                                        />
                                    )}
                                </div>
                            )}
                            {!investorId && property && unit && (
                                <SidebarSelectField
                                    id="investor"
                                    label={t("labels.investor")}
                                    placeholder={t("placeholders.search")}
                                    value={investor}
                                    onChange={setInvestor}
                                    options={investors.map(investor => ({
                                        label: `${investor.first_name} ${investor.last_name}`,
                                        value: investor.investor_id.toString()
                                    }))}
                                    onSearch={onSearchInvestor}
                                    isEndReached={isReachingInvestorsEnd}
                                    required
                                    labelOnTop
                                    isSearchable
                                />
                            )}
                        </div>

                        {/* GENERAL SECTION */}
                        <ExpandableSection
                            className="mt-4"
                            title={t("modals.add_unit_owner.general_title")}
                            isDefaultExpanded
                        >
                            <div className="row">
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="shares"
                                        type="number"
                                        label={t("labels.shares")}
                                        placeholder="50%"
                                        value={shares}
                                        errorMessage={sharesError}
                                        onClear={() => setShares("")}
                                        onChange={(value: string) => {
                                            let numericValue = parseFloat(value);

                                            if (numericValue < 0) {
                                                numericValue = 0;
                                            } else if (numericValue > 100) {
                                                numericValue = 100;
                                            }

                                            setShares(numericValue.toString());
                                            setSharesError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="purchase-price"
                                        type="number"
                                        label={t("labels.purchase_price")}
                                        placeholder="600,000.00"
                                        value={purchasePrice}
                                        errorMessage={purchasePriceError}
                                        onClear={() => setPurchasePrice("")}
                                        onChange={(value: string) => {
                                            let numericValue = parseFloat(value);

                                            if (numericValue < 0) {
                                                numericValue = 0;
                                            } else if (numericValue > 100000000) {
                                                numericValue = 100000000;
                                            }

                                            setPurchasePrice(numericValue.toString());
                                            setPurchasePriceError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                            </div>
                        </ExpandableSection>

                        {/* UPLOAD DOCUMENTS SECTION */}
                        <ExpandableSection
                            className="mt-4"
                            title={t("modals.add_unit_owner.upload_documents")}
                            isDefaultExpanded
                        >
                            <FileUpload
                                id="investor-documents"
                                allowedTypes=".pdf,image/*"
                                onFileSelected={onFileSelected}
                                currentFileCount={files.length}
                            />
                            {files.map((file, index) => (
                                <FileCard
                                    index={index}
                                    className="mt-3"
                                    key={file.filename}
                                    fileName={file.filename}
                                    fileSize={file.filesize}
                                    progress={file.progress}
                                    uploaded={file.uploaded}
                                    onClickRemove={() => removeFile(index)}
                                />
                            ))}
                        </ExpandableSection>
                    </div>
                    <div className="store-investor-btn">
                        {error && (
                            <p className="text-danger">{error}</p>
                        )}
                        <CustomButton
                            className="w-100"
                            title={t("modals.add_unit_owner.add_button")}
                            isLoading={isLoading}
                            onClick={onHandleCreateUnitOwner}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUnitOwnerSidebarModal;
