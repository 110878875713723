import { API_URL } from "../utils/constants";

export enum DocumentType {
  PASSPORT = "passport",
  PROOF_OF_ADDRESS = "proof-of-address",
  EMIRATES_ID = "emirates-id",
  VISA = "visa",
  TENANCY_CONTRACT = "tenancy-contract",
  POWER_OF_ATTORNEY = "power-of-attorney",
}

export interface IDocument {
  document_id: number;
  document_type?: DocumentType | null;
  file_type: string;
  file_path: string;
  file_name: string;
  valid_until: string | null;
  internal_document: boolean;
  created_at: string;
  updated_at: string;
}

export const downloadDocument = async (
  documentId: number
): Promise<Blob | null> => {
  const response = await fetch(`${API_URL}/documents/${documentId}/download`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};

export const deleteDocument = async (document_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/documents/${document_id}`, {
    method: "DELETE",
    credentials: "include",
  });

  return response.status === 200;
};
